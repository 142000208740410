function setCache(key, data) {
    if(data) {
        localStorage.setItem(key, JSON.stringify(data))
    }
}

function getCache(key) {
    const cache = localStorage.getItem(key)
    if(cache) {
        return JSON.parse(cache)
    }
    return null
}

module.exports = {
    setCache,
    getCache,
}
