function Api(method, path, cache = false){
    let api = new Object
    api.cache = cache;
    api.method = method;
    api.path = path;
    api.permit = function(){
        return this.method + '@' + api.path
    };
    return api;
}
module.exports = {
    _wx_official_jsSdkSignature: Api('get','/wx/official/jsSdkSignature'),
    _wx_official_preAuthorizationUrl: Api('get','/wx/official/preAuthorizationUrl'),
    _wx_official_login: Api('get','/wx/official/loginByCode'),
    _login: Api('post','/user/login'),
    _user_info: Api('get','/user/info'),
    _logout: Api('get','/user/logout'),

    _user_setPwd: Api('post','/user/setPwd'),
    _user_editProfile: Api('post','/user/editProfile'),
    _user_setChatStatus: Api('post','/user/setChatStatus'),

    _area_list: Api('get','/area/list', true),
    _area_location: Api('get','/area/location'),

    _file_uploadImage: Api('upload','/file/uploadImage'),

    _users_info: Api('get','/users/info'),
    _users_socialInfo: Api('get','/users/socialInfo'),
    _users_searchByKeyword: Api('get','/users/searchByKeyword'),
    _users_getUser2UserSocialResults: Api('get','/users/getUser2UserSocialResults'),
    _users_getUser2TrendsSocialResults: Api('get','/users/getUser2TrendsSocialResults'),

    _trends_publish: Api('post','/trends/publish'),
    _trends_searchByFilter: Api('get','/trends/searchByFilter'),
    _trends_searchByKeyword: Api('get','/trends/searchByKeyword'),
    _trends_queryUserTrends: Api('get','/trends/queryUserTrends'),
    _trends_setDelStatus: Api('post','/trends/setDelStatus'),
    _trends_getTrendsById: Api('get','/trends/getTrendsById'),

    _hotspot_query: Api('get','/hotspot/query'),

    _follow_queryFollows: Api('get','/follow/queryFollows'),
    _follow_queryFans: Api('get','/follow/queryFans'),
    _follow_follow: Api('post','/follow/follow'),
    _follow_unfollow: Api('post','/follow/unfollow'),

    _like_like: Api('post','/like/like'),
    _like_unlike: Api('post','/like/unlike'),

    _report_report: Api('post','/report/report'),
    _report_queryOfTrendsForUser: Api('get','/report/queryOfTrendsForUser'),

    _comment_queryTrendsComment: Api('get','/comment/queryTrendsComment'),
    _comment_queryTrendsCommentReplies: Api('get','/comment/queryTrendsCommentReplies'),
    _comment_commentTrends: Api('post','/comment/commentTrends'),
    _comment_replyComment: Api('post','/comment/replyComment'),
    _comment_setDelStatus: Api('post','/comment/setDelStatus'),

    _message_userNoticeList: Api('get','/message/userNoticeList'),
    _message_queryUserNoticeMessage: Api('get','/message/queryUserNoticeMessage'),
    _message_queryUserChats: Api('get','/message/queryUserChats'),
    _message_queryUserChatMessage: Api('get','/message/queryUserChatMessage'),
    _message_userChatLastMessageList: Api('get','/message/userChatLastMessageList'),
    _message_getUserChatByFriendUId: Api('get','/message/getUserChatByFriendUId'),
    _message_getUserChat: Api('get','/message/getUserChat'),

    _message_setUserChatNotReceived: Api('post','/message/setUserChatNotReceived'),
    _message_setUserChatReceived: Api('post','/message/setUserChatReceived'),
    _message_setExitUserChat: Api('post','/message/setExitUserChat'),
    _message_sendChatMessage: Api('post','/message/sendChatMessage'),

    _message_getUnreadNum: Api('get','/message/getUnreadNum'),

    _behavior_addTrendsLook: Api('get','/behavior/addTrendsLook'),
}
